<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <loader-page v-if="!latest_version_data"></loader-page>
        <template v-else>
            <p class="uk-margin-remove" style="font-size: 16px;">Latest Version</p>
            <div class="uk-margin-small-top uk-card uk-card-default uk-padding-small uk-border-rounded">
                <p class="uk-margin-remove uk-text-bold" style="font-size: 18px;">Version {{ latest_version_data && latest_version_data.version ? latest_version_data.version : '-' }}</p>
                <p class="uk-margin-remove" style="font-size: 14px;">
                    {{ isUpdated({ 
                        createdAt: latest_version_data && latest_version_data.created_at ? latest_version_data.created_at : null, 
                        updatedAt: latest_version_data && latest_version_data.updated_at ? latest_version_data.updated_at : null
                    }) }}
                </p>
                <div v-if="latest_version_data && latest_version_data.description" class="uk-margin-top">
                    <div class="plaform-version__description" v-html="latest_version_data.description" />
                </div>
                <div v-else class="uk-margin-top">-</div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatter from '@/utils/formatter';

export default {
    name: 'WhatsNew',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage')
    },
    computed: {
        ...mapGetters({
            latest_version_data: 'platform/latest_version_data'
        })
    },
    methods: {
        isUpdated({ createdAt, updatedAt }) {
            if (!createdAt && !updatedAt) {
                return '-';
            } else {
                if (!formatter.isSameDate(updatedAt, createdAt)) {
                    return formatter.dateCompleteNew(updatedAt);
                } else {
                    return formatter.dateCompleteNew(createdAt);
                }
            }
        },
    }
};
</script>

<style scoped>
.plaform-version__description >>> * {
  all: revert;
  background-color: unset !important;
}

.plaform-version__description >>> .ql-align-right {
  text-align: right;
}

.plaform-version__description >>> .ql-align-left {
  text-align: left;
}

.plaform-version__description >>> .ql-align-justify {
  text-align: justify;
}
</style>
